import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from 'reactstrap';
import qs from 'qs';
import PropTypes from 'prop-types';
import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { getToken } from '../../../services/auth';
import { brMoneyMask, servicesDescripton2 } from '../../../shared/utils/utils';

registerLocale('ptBR', ptBR);

const ConsumptionTable = ({ inicialDate, finalDate }) => {
  const client = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const page = 1;
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getHistoricData = useCallback(() => {
    setLoading(true);
    const url = `${process.env.REACT_APP_WAS_KEY}/wallet/request/historic`;
    const startDate = inicialDate.split('/').reverse().join('-');
    const endDate = finalDate.split('/').reverse().join('-');
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'x-api-key': client.owner
        },
        params: { startDate, endDate, page, limit },
        paramsSerializer(params) {
          return qs.stringify(params, { encode: false });
        }
      })
      .then((res) => {
        setTableData(res.data.rows);
        setTotalPages(res.data.totalPages);
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [client.owner, finalDate, inicialDate, limit, page]);

  useEffect(() => {
    getHistoricData();
  }, [getHistoricData]);

  const handlePageChange = (currentPageEvent) => {
    setCurrentPage(currentPageEvent);
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pages.push(
        <PaginationItem active={i === currentPage} key={i}>
          <PaginationLink
            onClick={() => handlePageChange(i)}
            className="custom-pagination"
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  };

  return (
    <Card>
      <CardBody className="panel__body dashboard__health-chart-card">
        <div className="panel__btns">
          <button
            className="panel__btn"
            aria-label="panel__btn"
            type="button"
            onClick={() => getHistoricData()}
          >
            <AutorenewIcon />
          </button>
        </div>
        <div className="panel__title" style={{ marginBottom: '6px' }}>
          <h5 className="bold-text text-left">Tabela de Consumo</h5>
        </div>
        {loading ? (
          <div style={{ height: '24rem' }}>
            <div className="panel__refresh">
              <LoadingIcon />
            </div>
          </div>
        ) : (
          ''
        )}
        <Row className="">
          <Col col={12}>
            <div className="payment_info mt-3">
              {!loading && (
                <>
                  <Table bordered hover responsive>
                    <thead>
                      <tr className="table-header">
                        <th>Data</th>
                        <th>Valor</th>
                        <th>Tipo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item) => (
                        <tr key={item.id}>
                          <td>
                            {format(
                              new Date(item.createdAt),
                              'dd/MM/yyyy hh:mm:ss'
                            )}
                          </td>
                          <td>R$ {brMoneyMask(item.amount)}</td>
                          <td>{servicesDescripton2[item.requestType]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination>
                    <PaginationItem disabled={page <= 1}>
                      <PaginationLink
                        first
                        onClick={() => handlePageChange(1)}
                      />
                    </PaginationItem>
                    <PaginationItem disabled={page <= 1}>
                      <PaginationLink
                        previous
                        onClick={() => handlePageChange(page - 1)}
                      />
                    </PaginationItem>
                    {renderPagination()}
                    <PaginationItem disabled={page >= totalPages}>
                      <PaginationLink
                        next
                        onClick={() => handlePageChange(page + 1)}
                      />
                    </PaginationItem>
                    <PaginationItem disabled={page >= totalPages}>
                      <PaginationLink
                        last
                        onClick={() => handlePageChange(totalPages)}
                      />
                    </PaginationItem>
                  </Pagination>
                </>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

ConsumptionTable.propTypes = {
  finalDate: PropTypes.string.isRequired,
  inicialDate: PropTypes.string.isRequired
};

export default ConsumptionTable;
